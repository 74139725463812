import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { Col, Grid, Row, styled } from '@smooth-ui/core-sc';
import Helmet from 'react-helmet';

import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

import { withSettings } from '../../containers/WebApp/SettingsContext';
import LoadingAnimation from '_platform/src/components/Loading/LoadingAnimation';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import BgPrimary from '../../components/CustomComponents/Backgrounds/BgPrimary';

import LoadAsync from '_platform/src/utils/LoadAsync';
import IconWrapper from '_platform/src/components/SvgIcon/IconWrapper';

import bgDesktop1x from './images/HomePage-Banner@1x.png';
import bgDesktop2x from './images/HomePage-Banner@2x.png';
import Logo from './images/Logo-HomePage@2x.png';

import Icon1 from './images/Icon1@2x.png';
import Icon2 from './images/Icon2@2x.png';
import Icon3 from './images/Icon3@2x.png';
import Icon4 from './images/Icon4@2x.png';
import Icon5 from './images/Icon5@2x.png';
import Icon6 from './images/Icon6@2x.png';

const StatementProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementProvider" */ '../StatementProvider/StatementProvider'
  )
);
const StatementIncentiveTiersMini = LoadAsync(() =>
  import(
    /* webpackChunkName: "statementIncentiveTiers" */ '../../components/StatementRecover/StatementIncentiveTiersMini'
  )
);

const fetchStatements = [
  {
    endpoint: '/Statement/v1/Incentive',
    section: 'incentive',
  },
  {
    endpoint: '/Statement/v1/Incentive/Tiers',
    section: 'incentiveTiers',
  },
];

const AlternateContainer = styled.div`
  padding: 2em 0;
  color: #fff;
  text-align: center;
  background-color: #fff;
  background-image: url(${bgDesktop1x});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url(${bgDesktop2x});
  }

  h1 {
    font-weight: bold;
    font-size: 50px;
  }
  p {
    line-height: 1.5;
  }
`;

const ExplainedHeading = styled.div`
  background-color: #1a243e;
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  padding: 0.25rem 0;
`;

const PageContainer = styled.div`
  h4 {
    min-height: 90px;
  }
`;

const HomePage = ({ settings }) => {
  return (
    <PageContainer>
      <Helmet>
        {/* <meta name="description" content="Page description" /> */}
      </Helmet>

      <PrivateComponent
        redirectOnError={{
          pathname: settings.settingsApp.loginPagePath || '/login/',
        }}
      >
        <AlternateContainer>
          <Grid>
            <Row justifyContent="center">
              <Col md={6}>
                <img src={Logo} alt="Konnichiwa Helsinki" />
              </Col>
            </Row>
            <Row justifyContent="center">
              <Col md={8}>
                <h3 className="text--uppercase">
                  Konnichiwa Helsinki Distributor
                  <br />
                  Incentive Program 2024/25
                </h3>
                <div className="spacer">
                  <LiteYouTubeEmbed
                    id="jUJcUIzJQv8"
                    title="Konnichiwa Helsinki"
                  />
                </div>
              </Col>
            </Row>
          </Grid>
        </AlternateContainer>
        <BgPrimary>
          <StatementProvider
            fetch={fetchStatements}
            render={({ statement, onStatementUpdateTargetRequest }) => {
              function onTargetUpdate(values) {
                return onStatementUpdateTargetRequest(values, fetchStatements);
              }

              return !statement || !statement.data ? (
                <LoadingAnimation /> // Loader instead of `null`?
              ) : (
                <StatementIncentiveTiersMini
                  statement={statement.data}
                  onStatementUpdateTargetRequest={onTargetUpdate}
                />
              );
            }}
          />
        </BgPrimary>
        <ExplainedHeading>
          <h2 className="text--center text--bold text--uppercase">
            My Activity Explained
          </h2>
        </ExplainedHeading>
        <BgPrimary>
          <Grid className="spacer--large">
            <Row justifyContent="center">
              <Col cs={6} md={3} className="text--center">
                <IconWrapper height="130px" width="130px">
                  <img src={Icon1} alt="Sales Accurate To" />
                </IconWrapper>
                <h4 className="text--uppercase">Sales Accurate To</h4>
                <p>
                  This is the latest week ending date that sales (purchases)
                  have been updated to.
                </p>
              </Col>
              <Col cs={6} md={3} className="text--center">
                <IconWrapper height="130px" width="130px">
                  <img src={Icon2} alt="Samurai Swords Achieved" />
                </IconWrapper>
                <h4 className="text--uppercase">Samurai Swords Achieved</h4>
                <p>
                  This is your total Samurai Swords earned across all 3
                  suppliers for Australia and 1 supplier for New Zealand.
                </p>
              </Col>
              <Col cs={6} md={3} className="text--center">
                <IconWrapper height="130px" width="130px">
                  <img src={Icon2} alt="Samurai Sword Projected" />
                </IconWrapper>
                <h4 className="text--uppercase">Samurai Sword Projected</h4>
                <p>
                  This is your total Samurai Sword forecast for all 3 suppliers
                  for Australia and 1 supplier for New Zealand, based on the
                  following calculation:
                  <br />
                  Sales to date, divided by the days elapsed in the program.
                  Multiplied by the days remaining in the program.
                </p>
                <p>
                  *Please note that this projection will vary depending on your
                  purchase patterns.
                </p>
              </Col>
              <Col cs={6} md={3} className="text--center">
                <IconWrapper height="130px" width="130px">
                  <img src={Icon3} alt="Sales Target, 1st Samurai Sword" />
                </IconWrapper>
                <h4 className="text--uppercase">
                  Sales Target, 1st Samurai Sword
                </h4>
                <p>
                  This is your first sales (purchase) target. Achieve this
                  target and you will receive your 1st Samurai Sword.
                </p>
              </Col>
              <Col cs={6} md={3} className="text--center">
                <IconWrapper height="130px" width="130px">
                  <img src={Icon4} alt="Sales to Date" />
                </IconWrapper>
                <h4 className="text--uppercase">Sales to Date</h4>
                <p>
                  This is your sales value/volume (purchases) for the applicable
                  supplier only.
                </p>
              </Col>
              <Col cs={6} md={3} className="text--center">
                <IconWrapper height="130px" width="130px">
                  <img src={Icon5} alt="Your Progress Towards" />
                </IconWrapper>
                <h4 className="text--uppercase">Your Progress Towards</h4>
                <p>
                  The bar indicates your progress towards your current Samurai
                  Sword as indicated. It also indicates your sales (purchases)
                  to date towards your next Samurai Sword and the sales
                  value/volume (purchases) still required.
                </p>
              </Col>
              <Col cs={6} md={3} className="text--center">
                <IconWrapper height="130px" width="130px">
                  <img src={Icon6} alt="Days Remaining" />
                </IconWrapper>
                <h4 className="text--uppercase">Days Remaining</h4>
                <p>
                  This indicates how many buying days are remaining in the
                  program.
                </p>
              </Col>
              <Col cs={6} md={3} className="text--center">
                <IconWrapper height="130px" width="130px">
                  <img src={Icon2} alt="Each Additional Samurai Sword Value" />
                </IconWrapper>
                <h4 className="text--uppercase">
                  Each Additional Samurai Sword Value
                </h4>
                <p>
                  When you achieve the equivalent value in sales (purchases) you
                  will receive 1 additional Samurai Sword each time.
                </p>
                <p>
                  *Note that this is applicable ONLY after you achieve your
                  initial Samurai Sword.
                </p>
              </Col>
            </Row>
          </Grid>
        </BgPrimary>
      </PrivateComponent>
    </PageContainer>
  );
};

HomePage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withSettings(HomePage);
